<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">长处方药详情</div>   
        <div class="rb1" @click="goback">
          <img src="@/assets/gb.png" class="icon" />
          <div class="text">关闭</div>
        </div>   
      </div>
      <div class="content">
        <div class="form">
          <div class="item">
            <div class="listbox">
              <img src="@/assets/zt_xz.png" class="ztimg" />                                        
            </div>       
          </div>

          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">{{ name }}</span>
                <span class="m">{{ mobile }}</span>   
                <span class="m">{{ certificate_code }}</span>                           
              </div>
              <!--<div class="name">
                <span class="m">{{ certificate_code }}</span>                          
              </div>
              <div class="name">
                <span class="m">{{ address }}</span>                            
              </div>-->
            </div>     
          </div>

          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">就诊人：</span>
                <span class="m">{{ name }}</span>
                <span class="m">{{ sex }}</span>
                <span >{{ age }}岁</span>                             
              </div>
              <div class="name">
                <span class="m">诊</span>   
                <span class="m">断：</span>  
                <span class="m">{{ diagnosis }}</span>                       
              </div>
              <div class="name">
                <span class="m">医</span>   
                <span class="m">生：</span> 
                <span class="m">{{ doctor }}</span>   
                <span class="r" @click="prescription">查看处方笺</span>                       
              </div>
            </div>     
          </div>

          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">替米沙坦胶囊</span>
                <span class="m l">40mg*36粒</span>                            
              </div>
              <div class="name dd" @click="onDdx_1(1)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag11" class="dxicon"/>
                  <img src="@/assets/ddx2.png" v-else class="dxicon"/>
                </span>
                <span class="m">替米沙坦胶囊</span>
                <span class="m">40mg*21粒</span> 
                <span class="r z">¥{{ price11 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_1(1)">
                <span class="d">江西仁和药业有限公司</span>                          
              </div>
              <div class="name dd" @click="onDdx_1(2)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag12" class="dxicon"/>
                  <img src="@/assets/ddx2.png" v-else class="dxicon"/>
                </span>
                <span class="m">替米沙坦胶囊</span>
                <span class="m">40mg*21粒</span> 
                <span class="r z">¥{{ price12 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_1(2)">
                <span class="d">江西杏林白马药业有限公司</span>                          
              </div>
              <div class="name">
                <span class="m">购药数量</span>    
                <img src="@/assets/jian_num.png" @click="cutnum(1)" class="icon" />
                <span class="s">{{ num1 }}</span> 
                <img src="@/assets/jia_num.png" @click="addnum(1)" class="icon" />                       
              </div>
              <div class="name">
                <span class="r g">¥{{ pricexj1 }}</span> 
                <span class="m">小计</span> 
              </div> 
              <div class="name">
                <span class="r red">-&nbsp;¥{{ pricebx1 }}</span> 
                <span class="m">医保报销</span> 
              </div>
            </div>     
          </div>
          
          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">苯磺酸氨氯地平片</span>
                <span class="m l">5mg*7片</span>                            
              </div>
              <div class="name dd" @click="onDdx_2(1)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag21" class="dxicon" />
                  <img src="@/assets/ddx2.png" v-if="ddrflag21 === false" class="dxicon" />
                </span>
                <span class="m">苯磺酸氨氯地平片</span>
                <span class="m">5mg*14片</span> 
                <span class="r z">¥{{ price21 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_2(1)">
                <span class="d">苏州东瑞制药有限公司</span>                          
              </div>
              <div class="name dd" @click="onDdx_2(2)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag22" class="dxicon" />
                  <img src="@/assets/ddx2.png" v-if="ddrflag22 === false" class="dxicon" />
                </span>
                <span class="m">苯磺酸氨氯地平片</span>
                <span class="m">5mg*28片</span> 
                <span class="r z">¥{{ price22 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_2(2)">
                <span class="d">苏州东瑞制药有限公司</span>                          
              </div>
              <div class="name dd" @click="onDdx_2(3)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag23" class="dxicon" />
                  <img src="@/assets/ddx2.png" v-if="ddrflag23 === false" class="dxicon" />
                </span>
                <span class="m">苯磺酸氨氯地平片</span>
                <span class="m">5mg*7片</span> 
                <span class="r z">¥{{ price23 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_2(3)">
                <span class="d">辉瑞制药有限公司</span>                          
              </div>
              <div class="name dd" @click="onDdx_2(4)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag24" class="dxicon" />
                  <img src="@/assets/ddx2.png" v-if="ddrflag24 === false" class="dxicon" />
                </span>
                <span class="m">苯磺酸氨氯地平片</span>
                <span class="m">5mg*7片</span> 
                <span class="r z">¥{{ price24 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_2(4)">
                <span class="d">华润赛科药业有限责任公司</span>                          
              </div>
              <div class="name">
                <span class="m">购药数量</span>    
                <img src="@/assets/jian_num.png" @click="cutnum(2)" class="icon" />
                <span class="s">{{ num2 }}</span> 
                <img src="@/assets/jia_num.png" @click="addnum(2)" class="icon" />                       
              </div>
              <div class="name">
                <span class="r g">¥{{ pricexj2 }}</span> 
                <span class="m">小计</span> 
              </div> 
              <div class="name">
                <span class="r red">-&nbsp;¥{{ pricebx2 }}</span> 
                <span class="m">医保报销</span> 
              </div>
            </div>     
          </div> 
          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">依那普利片</span>
                <span class="m l">5mg*7片</span>                            
              </div>
              <div class="name dd" @click="onDdx_3(1)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag31" class="dxicon" />
                  <img src="@/assets/ddx2.png" v-if="ddrflag31 === false" class="dxicon" />
                </span>
                <span class="m">依那普利片</span>
                <span class="m">10mg*16片</span> 
                <span class="r z">¥{{ price31 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_3(1)">
                <span class="d">扬子江药业集团江苏制药</span>                          
              </div>
              <div class="name dd" @click="onDdx_3(2)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag32" class="dxicon" />
                  <img src="@/assets/ddx2.png" v-if="ddrflag32 === false" class="dxicon" />
                </span>
                <span class="m">依那普利片</span>
                <span class="m">10mg*20片</span> 
                <span class="r z">¥{{ price32 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_3(2)">
                <span class="d">广东彼迪药业有限公司</span>                          
              </div>
              <div class="name dd" @click="onDdx_3(3)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag33" class="dxicon" />
                  <img src="@/assets/ddx2.png" v-if="ddrflag33 === false" class="dxicon" />
                </span>
                <span class="m">依那普利片</span>
                <span class="m">10mg*10片</span> 
                <span class="r z">¥{{ price33 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_3(3)">
                <span class="d">辰欣药业股份有限公司</span>                          
              </div>
              <div class="name dd" @click="onDdx_3(4)">
                <span class="m kg">
                  <img src="@/assets/ddx1.png" v-if="ddrflag34" class="dxicon" />
                  <img src="@/assets/ddx2.png" v-if="ddrflag34 === false" class="dxicon" />
                </span>
                <span class="m">依那普利片</span>
                <span class="m">10mg*16片</span> 
                <span class="r z">¥{{ price34 }}</span> 
              </div> 
              <div class="name dd" @click="onDdx_3(4)">
                <span class="d">湖南千金湘江药业股份有限公司</span>                          
              </div>
              <div class="name">
                <span class="m">购药数量</span>    
                <img src="@/assets/jian_num.png" @click="cutnum(3)" class="icon" />
                <span class="s">{{ num3 }}</span> 
                <img src="@/assets/jia_num.png" @click="addnum(3)" class="icon" />                       
              </div>
              <div class="name">
                <span class="r g">¥{{ pricexj3 }}</span> 
                <span class="m">小计</span> 
              </div> 
              <div class="name">
                <span class="r red">-&nbsp;¥{{ pricebx3 }}</span> 
                <span class="m">医保报销</span> 
              </div>
            </div>     
          </div> 
          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">硝苯地平缓释片</span>
                <span class="m l">20mg*24片</span>                            
              </div>
              <div class="name">
                <span class="m red">无匹配药品</span>
              </div> 
            </div>     
          </div> 
          <div class="item">
            <div class="listbox">
              <div class="name">
                <span class="m">费用明细</span>                         
              </div>
              <div class="name">
                <span class="r g">¥{{ pricezj }}</span> 
                <span class="m">总计</span> 
              </div> 
              <div class="name">
                <span class="r red">-&nbsp;¥{{ pricebx }}</span> 
                <span class="m">医保报销</span> 
              </div>
            </div>     
          </div>   
          <div class="item">
            <div class="listbox"> 
              <div class="name">
                <span class="r yf">¥{{ pricehj }}</span> 
                <span class="m">应付金额</span> 
              </div>
            </div>     
          </div>     
        </div>       
        <div class="button primary mm" @click="submit">提交订单</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  created() {

    this.token_id = this.$route.query.token_id;
    this.openid = this.$route.query.openid;
    this.user_openid = this.$route.query.user_openid;
    this.certificate_code = this.$route.query.certificate_code;
    this.mobile = this.$route.query.mobile;
    this.name = this.$route.query.name;
    this.SiteCode = this.$route.query.SiteCode;
    this.ClientID = this.$route.query.ClientID;   
    this.age = this.$route.query.age; 
    this.sex = this.$route.query.sex; 
  },
  data() {
    return {
      token_id: '',
      openid: '',
      user_openid: '',
      certificate_code: '',
      mobile: '',
      name: '',
      SiteCode: '',
      ClientID: '',
      age: '',
      sex: '',
      address: '吉林省长春市朝阳区湖西路1028号长影21栋',
      diagnosis: '高血压',
      doctor: '刘鑫',       
      ddrflag11: true,
      ddrflag12: false,
      ddrflag21: true,
      ddrflag22: false,
      ddrflag23: false,
      ddrflag24: false,
      ddrflag31: true,
      ddrflag32: false,
      ddrflag33: false,
      ddrflag34: false,
      num1: 1,
      num2: 1,
      num3: 1,
      price11: 15,
      price12: 12.5,
      pricexj1: 15,
      pricebx1: 10.5,
      price21: 12,
      price22: 20,
      price23: 8,
      price24: 6,
      pricexj2: 12,
      pricebx2: 8.4,
      price31: 18,
      price32: 16,
      price33: 9.5,
      price34: 18,
      pricexj3: 18,
      pricebx3: 12.6,
      pricezj: 45, 
      pricebx: 31.5,
      pricehj: 13.5
    };
  },
  methods: {
    prescription() {
      Toast('暂未开放');
    },
    goback() {
      Dialog.confirm({
        message: "退出操作页面！"
      }).then(async () => {
        window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
      });
    },
    addnum(i){
      if(i === 1){
        this.num1 = this.num1 + 1;             
      }else if(i === 2){
        this.num2 = this.num2 + 1;
      }else if(i === 3){
        this.num3 = this.num3 + 1;
      }

      this.hj(i); 
      
    },
    cutnum(i){
      if(i === 1){
        if(this.num1>1){
          this.num1 = this.num1 - 1;
          this.hj(i); 
        }       
      }else if(i === 2){
        if(this.num2>1){
          this.num2 = this.num2 - 1;
          this.hj(i); 
        }
      }else if(i === 3){
        if(this.num3>1){
          this.num3 = this.num3 - 1;
          this.hj(i); 
        }
      }   
    },
    hj(i){
      if(i === 1){
        if(this.ddrflag11){
          this.pricexj1 = this.price11*this.num1;
          this.pricebx1 = (this.pricexj1*0.7).toFixed(2);
          this.pricezj = ((this.price11*this.num1)+this.pricexj2+this.pricexj3).toFixed(2);
          this.pricebx = (((this.price11*this.num1)+this.pricexj2+this.pricexj3)*0.7).toFixed(2);
          this.pricehj = (((this.price11*this.num1)+this.pricexj2+this.pricexj3)-(((this.price11*this.num1)+this.pricexj2+this.pricexj3)*0.7)).toFixed(2);
        }else if(this.ddrflag12){
          this.pricexj1 = this.price12*this.num1;
          this.pricebx1 = (this.pricexj1*0.7).toFixed(2);
          this.pricezj = ((this.price12*this.num1)+this.pricexj2+this.pricexj3).toFixed(2);
          this.pricebx = (((this.price12*this.num1)+this.pricexj2+this.pricexj3)*0.7).toFixed(2);
          this.pricehj = (((this.price12*this.num1)+this.pricexj2+this.pricexj3)-(((this.price12*this.num1)+this.pricexj2+this.pricexj3)*0.7)).toFixed(2);
        }     
      }else if(i === 2){
        if(this.ddrflag21){
          this.pricexj2 = this.price21*this.num2;
          this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
          this.pricezj = (this.pricexj1+(this.price21*this.num2)+this.pricexj3).toFixed(2);
          this.pricebx = ((this.pricexj1+(this.price21*this.num2)+this.pricexj3)*0.7).toFixed(2);
          this.pricehj = ((this.pricexj1+(this.price21*this.num2)+this.pricexj3)-((this.pricexj1+(this.price21*this.num2)+this.pricexj3)*0.7)).toFixed(2);

        }else if(this.ddrflag22){
          this.pricexj2 = this.price22*this.num2;
          this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
          this.pricezj = (this.pricexj1+(this.price22*this.num2)+this.pricexj3).toFixed(2);
          this.pricebx = ((this.pricexj1+(this.price22*this.num2)+this.pricexj3)*0.7).toFixed(2);
          this.pricehj = ((this.pricexj1+(this.price22*this.num2)+this.pricexj3)-((this.pricexj1+(this.price22*this.num2)+this.pricexj3)*0.7)).toFixed(2);

        }else if(this.ddrflag23){
          this.pricexj2 = this.price23*this.num2;
          this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
          this.pricezj = (this.pricexj1+(this.price23*this.num2)+this.pricexj3).toFixed(2);
          this.pricebx = ((this.pricexj1+(this.price23*this.num2)+this.pricexj3)*0.7).toFixed(2);
          this.pricehj = ((this.pricexj1+(this.price23*this.num2)+this.pricexj3)-((this.pricexj1+(this.price23*this.num2)+this.pricexj3)*0.7)).toFixed(2);

        }else if(this.ddrflag24){
          this.pricexj2 = this.price24*this.num2;
          this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
          this.pricezj = (this.pricexj1+(this.price24*this.num2)+this.pricexj3).toFixed(2);
          this.pricebx = ((this.pricexj1+(this.price24*this.num2)+this.pricexj3)*0.7).toFixed(2);
          this.pricehj = ((this.pricexj1+(this.price24*this.num2)+this.pricexj3)-((this.pricexj1+(this.price24*this.num2)+this.pricexj3)*0.7)).toFixed(2);

        }
      }else if(i === 3){
        if(this.ddrflag31){
          this.pricexj3 = this.price31*this.num3;
          this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
          this.pricezj = (this.pricexj1+this.pricexj2+(this.price31*this.num3)).toFixed(2);
          this.pricebx = ((this.pricexj1+this.pricexj2+(this.price31*this.num3))*0.7).toFixed(2);
          this.pricehj = ((this.pricexj1+this.pricexj2+(this.price31*this.num3))-((this.pricexj1+this.pricexj2+(this.price31*this.num3))*0.7)).toFixed(2);
        }else if(this.ddrflag32){
          this.pricexj3 = this.price32*this.num3;
          this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
          this.pricezj = (this.pricexj1+this.pricexj2+(this.price32*this.num3)).toFixed(2);
          this.pricebx = ((this.pricexj1+this.pricexj2+(this.price32*this.num3))*0.7).toFixed(2);
          this.pricehj = ((this.pricexj1+this.pricexj2+(this.price32*this.num3))-((this.pricexj1+this.pricexj2+(this.price32*this.num3))*0.7)).toFixed(2);
        }else if(this.ddrflag33){
          this.pricexj3 = this.price33*this.num3;
          this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
          this.pricezj = (this.pricexj1+this.pricexj2+(this.price33*this.num3)).toFixed(2);
          this.pricebx = ((this.pricexj1+this.pricexj2+(this.price33*this.num3))*0.7).toFixed(2);
          this.pricehj = ((this.pricexj1+this.pricexj2+(this.price33*this.num3))-((this.pricexj1+this.pricexj2+(this.price33*this.num3))*0.7)).toFixed(2);
        }else if(this.ddrflag34){
          this.pricexj3 = this.price34*this.num3;
          this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
          this.pricezj = (this.pricexj1+this.pricexj2+(this.price34*this.num3)).toFixed(2);
          this.pricebx = ((this.pricexj1+this.pricexj2+(this.price34*this.num3))*0.7).toFixed(2);
          this.pricehj = ((this.pricexj1+this.pricexj2+(this.price34*this.num3))-((this.pricexj1+this.pricexj2+(this.price34*this.num3))*0.7)).toFixed(2);
        }
      }   
    },
    onDdx_1(flag) {
      if(flag==1){
        this.ddrflag11=true;
        this.ddrflag12=false;
        this.pricexj1 = this.price11*this.num1;
        this.pricebx1 = (this.pricexj1*0.7).toFixed(2);
        this.pricezj = ((this.price11*this.num1)+this.pricexj2+this.pricexj3).toFixed(2);
        this.pricebx = (((this.price11*this.num1)+this.pricexj2+this.pricexj3)*0.7).toFixed(2);
        this.pricehj = (((this.price11*this.num1)+this.pricexj2+this.pricexj3)-(((this.price11*this.num1)+this.pricexj2+this.pricexj3)*0.7)).toFixed(2);
      }else if(flag==2){
        this.ddrflag11=false;
        this.ddrflag12=true;
        this.pricexj1 = this.price12*this.num1;
        this.pricebx1 = (this.pricexj1*0.7).toFixed(2);
        this.pricezj = ((this.price12*this.num1)+this.pricexj2+this.pricexj3).toFixed(2);
        this.pricebx = (((this.price12*this.num1)+this.pricexj2+this.pricexj3)*0.7).toFixed(2);
        this.pricehj = (((this.price12*this.num1)+this.pricexj2+this.pricexj3)-(((this.price12*this.num1)+this.pricexj2+this.pricexj3)*0.7)).toFixed(2);
      }
    },
    onDdx_2(flag) {
      if(flag==1){
        this.ddrflag21=true;
        this.ddrflag22=false;
        this.ddrflag23=false;
        this.ddrflag24=false;
        this.pricexj2 = this.price21*this.num2;
        this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
        this.pricezj = (this.pricexj1+(this.price21*this.num2)+this.pricexj3).toFixed(2);
        this.pricebx = ((this.pricexj1+(this.price21*this.num2)+this.pricexj3)*0.7).toFixed(2);
        this.pricehj = ((this.pricexj1+(this.price21*this.num2)+this.pricexj3)-((this.pricexj1+(this.price21*this.num2)+this.pricexj3)*0.7)).toFixed(2);
      }else if(flag==2){
        this.ddrflag21=false;
        this.ddrflag22=true;
        this.ddrflag23=false;
        this.ddrflag24=false;
        this.pricexj2 = this.price22*this.num2;
        this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
        this.pricezj = (this.pricexj1+(this.price22*this.num2)+this.pricexj3).toFixed(2);
        this.pricebx = ((this.pricexj1+(this.price22*this.num2)+this.pricexj3)*0.7).toFixed(2);
        this.pricehj = ((this.pricexj1+(this.price22*this.num2)+this.pricexj3)-((this.pricexj1+(this.price22*this.num2)+this.pricexj3)*0.7)).toFixed(2);
      }else if(flag==3){
        this.ddrflag21=false;
        this.ddrflag22=false;
        this.ddrflag23=true;
        this.ddrflag24=false;
        this.pricexj2 = this.price23*this.num2;
        this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
        this.pricezj = (this.pricexj1+(this.price23*this.num2)+this.pricexj3).toFixed(2);
        this.pricebx = ((this.pricexj1+(this.price23*this.num2)+this.pricexj3)*0.7).toFixed(2);
        this.pricehj = ((this.pricexj1+(this.price23*this.num2)+this.pricexj3)-((this.pricexj1+(this.price23*this.num2)+this.pricexj3)*0.7)).toFixed(2);
      }else if(flag==4){
        this.ddrflag21=false;
        this.ddrflag22=false;
        this.ddrflag23=false;
        this.ddrflag24=true;
        this.pricexj2 = this.price24*this.num2;
        this.pricebx2 = (this.pricexj2*0.7).toFixed(2);
        this.pricezj = (this.pricexj1+(this.price24*this.num2)+this.pricexj3).toFixed(2);
        this.pricebx = ((this.pricexj1+(this.price24*this.num2)+this.pricexj3)*0.7).toFixed(2);
        this.pricehj = ((this.pricexj1+(this.price24*this.num2)+this.pricexj3)-((this.pricexj1+(this.price24*this.num2)+this.pricexj3)*0.7)).toFixed(2);
      }
    },
    onDdx_3(flag) {
      if(flag==1){
        this.ddrflag31=true;
        this.ddrflag32=false;
        this.ddrflag33=false;
        this.ddrflag34=false;
        this.pricexj3 = this.price31*this.num3;
        this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
        this.pricezj = (this.pricexj1+this.pricexj2+(this.price31*this.num3)).toFixed(2);
        this.pricebx = ((this.pricexj1+this.pricexj2+(this.price31*this.num3))*0.7).toFixed(2);
        this.pricehj = ((this.pricexj1+this.pricexj2+(this.price31*this.num3))-((this.pricexj1+this.pricexj2+(this.price31*this.num3))*0.7)).toFixed(2);
      }else if(flag==2){
        this.ddrflag31=false;
        this.ddrflag32=true;
        this.ddrflag33=false;
        this.ddrflag34=false;
        this.pricexj3 = this.price32*this.num3;
        this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
        this.pricezj = (this.pricexj1+this.pricexj2+(this.price32*this.num3)).toFixed(2);
        this.pricebx = ((this.pricexj1+this.pricexj2+(this.price32*this.num3))*0.7).toFixed(2);
        this.pricehj = ((this.pricexj1+this.pricexj2+(this.price32*this.num3))-((this.pricexj1+this.pricexj2+(this.price32*this.num3))*0.7)).toFixed(2);
      }else if(flag==3){
        this.ddrflag31=false;
        this.ddrflag32=false;
        this.ddrflag33=true;
        this.ddrflag34=false;
        this.pricexj3 = this.price33*this.num3;
        this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
        this.pricezj = (this.pricexj1+this.pricexj2+(this.price33*this.num3)).toFixed(2);
        this.pricebx = ((this.pricexj1+this.pricexj2+(this.price33*this.num3))*0.7).toFixed(2);
        this.pricehj = ((this.pricexj1+this.pricexj2+(this.price33*this.num3))-((this.pricexj1+this.pricexj2+(this.price33*this.num3))*0.7)).toFixed(2);
      }else if(flag==4){
        this.ddrflag31=false;
        this.ddrflag32=false;
        this.ddrflag33=false;
        this.ddrflag34=true;
        this.pricexj3 = this.price34*this.num3;
        this.pricebx3 = (this.pricexj3*0.7).toFixed(2);
        this.pricezj = (this.pricexj1+this.pricexj2+(this.price34*this.num3)).toFixed(2);
        this.pricebx = ((this.pricexj1+this.pricexj2+(this.price34*this.num3))*0.7).toFixed(2);
        this.pricehj = ((this.pricexj1+this.pricexj2+(this.price34*this.num3))-((this.pricexj1+this.pricexj2+(this.price34*this.num3))*0.7)).toFixed(2);
      }
    },
    async submit() {
      /*if (isEmpty(this.name)) {
        Toast('吧台名称不可以为空!');
        return;
      }*/
      var ypname1,factory1,metering1,price1,number1;
      if(this.ddrflag11){
        ypname1 = '替米沙坦胶囊';
        factory1 = '江西仁和药业有限公司';
        metering1 = '40mg*21粒';
        price1 = this.price11;
        number1 = this.num1;
      }else if(this.ddrflag12){
        ypname1 = '替米沙坦胶囊';
        factory1 = '江西杏林白马药业有限公司';
        metering1 = '40mg*21粒';
        price1 = this.price12;
        number1 = this.num1;
      }     

      var ypname2,factory2,metering2,price2,number2;
      if(this.ddrflag21){
        ypname2 = '苯磺酸氨氯地平片';
        factory2 = '苏州东瑞制药有限公司';
        metering2 = '5mg*14片';
        price2 = this.price21;
        number2 = this.num2;
      }else if(this.ddrflag22){
        ypname2 = '苯磺酸氨氯地平片';
        factory2 = '苏州东瑞制药有限公司';
        metering2 = '5mg*28片';
        price2 = this.price22;
        number2 = this.num2;
      }else if(this.ddrflag23){
        ypname2 = '苯磺酸氨氯地平片';
        factory2 = '辉瑞制药有限公司';
        metering2 = '5mg*7片';
        price2 = this.price23;
        number2 = this.num2;
      }else if(this.ddrflag24){
        ypname2 = '苯磺酸氨氯地平片';
        factory2 = '华润赛科药业有限责任公司';
        metering2 = '5mg*7片';
        price2 = this.price24;
        number2 = this.num2;
      }

      var ypname3,factory3,metering3,price3,number3;
      if(this.ddrflag31){
        ypname3 = '依那普利片';
        factory3 = '扬子江药业集团江苏制药';
        metering3 = '10mg*16片';
        price3 = this.price31;
        number3 = this.num3;
      }else if(this.ddrflag32){
        ypname3 = '依那普利片';
        factory3 = '广东彼迪药业有限公司';
        metering3 = '10mg*20片';
        price3 = this.price32;
        number3 = this.num3;
      }else if(this.ddrflag33){
        ypname3 = '依那普利片';
        factory3 = '辰欣药业股份有限公司';
        metering3 = '10mg*10片';
        price3 = this.price33;
        number3 = this.num3;
      }else if(this.ddrflag34){
        ypname3 = '依那普利片';
        factory3 = '湖南千金湘江药业股份有限公司';
        metering3 = '10mg*16片';
        price3 = this.price34;
        number3 = this.num3;
      }


      let { data } = await this.axios.post(
        '/medicines/yd_tlService/yd_syy_messages',
        qs.stringify({ 
          token_id: this.token_id, 
          openid: this.openid, 
          user_openid: this.user_openid, 
          certificate_code: this.certificate_code, 
          mobile: this.mobile, 
          name: this.name, 
          SiteCode: this.SiteCode, 
          ClientID: this.ClientID, 
          sex: this.sex, 
          age: this.age, 
          ypname1: ypname1,
          factory1: factory1,
          metering1: metering1,
          price1: price1,
          number1: number1,
          ypname2: ypname2,
          factory2: factory2,
          metering2: metering2,
          price2: price2,
          number2: number2,
          ypname3: ypname3,
          factory3: factory3,
          metering3: metering3,
          price3: price3,
          number3: number3
        })
      );
      if (data.flag === '0') {
        Dialog.confirm({
          showCancelButton: false,
          message: data.message+"，退出操作页面！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });
      } else {
        Toast(data.message);
        //Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 35px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}
.content {
  margin-top: 0px;
  padding: 10px 40px 50px 40px;
}
.listbox {
  flex: 1;
  overflow: hidden;
  .red {
    color: rgb(180, 4, 4);
  }  
  .ztimg {
    width: 100%;
    vertical-align: middle;
  } 
  .name {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;
    &.dd {
      font-size: 25px;
      color: rgb(136, 134, 134);
    }
    .icon {
      width: 28px;
      height: 28px;
      display: block;
      float: right;
      margin-top: 15px;
    }
    .dxicon {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    } 
    .m {
      float: left;
      margin-right: 30px;
      &.kg {
        margin-right: 10px;
        margin-left: 30px;
      }
      &.red {
        color: rgb(236, 8, 38);
      }
      &.l {
        color: rgb(141, 202, 228);
        font-size: 25px;
      }
    }
    .d {
      float: left;
      margin-left: 70px;
    }
    .r {
      float: right;
      color: rgb(17, 61, 202);
      &.bq {
        width: 120px;
        margin-right: 30px;
      }
      &.red {
        color: rgb(236, 8, 38);
      }
      &.g {
        color: rgb(8, 182, 159);
      }
      &.z {
        color: rgb(127, 49, 218);
      }
      &.yf {
        color: rgb(1, 59, 248);
      }
    }
    .s {
      float: right;
      color: rgb(136, 134, 134);
      margin-right: 10px;
      margin-left: 10px;
    }
    .zdy {
      float: left;
    }
    .input {
      vertical-align: middle;
      width: 125px;
      border: 0;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      float: right;
      &.rr {
        margin-left: 72px;
        float: left;
        width: 399px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px;
      float: right;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(136, 134, 134);
    }
    .txt1 {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
    .arrow {
      float: right;
      display: block;
      //margin-left: 10px;
      margin-top: 8px;
      height: 40px;
    }
    .text {
      font-size: 25px;
      float: right;
      color: rgb(136, 134, 134);
    }
    .form_select1 {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 98% center no-repeat;
      background-size: 26px;
      width: 100%;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      text-align: right;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      float: right;
      direction: rtl;
      padding-right: 40px;
    }
    select:focus { outline: none; }
  }
  .name1 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 35px;
    font-weight:800;
    margin-top: 52px;
    margin-left: 34px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
    } 
    .txt {
      vertical-align: middle;
      font-size: 25px;
      float: right;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(136, 134, 134);
    }
  }
  .unames {
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    color: #666666;
    margin-top: 30px;
    margin-left: 36px;
  }
  .unames1 {
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    color: #666666;
    margin-top: 10px;
    margin-left: 36px;
  }
  
}
.listbox1 {
  flex: 1;
  overflow: hidden;
  width: 50px;
  .name {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;   
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
      margin-right: 6px;
      &.ll {
        margin-left: 10px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
    .arrow {
      float: right;
      vertical-align: middle;
      height: 40px;
    }
    .text {
      font-size: 25px;
      float: right;
    }
    .form_select {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 98% center no-repeat;
      background-size: 26px;
      width: 100%;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      direction: rtl;
      padding-right: 40px;
    } 
    select:focus { outline: none; }

    .form_select2 {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/xl1.png') 56px center no-repeat;
      background-size: 26px;
      width: 80px;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      margin-left: 15px;
    } 
    select:focus { outline: none; } 

  }  
  .name1 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
      margin-right: 6px;
      &.ll {
        margin-left: 10px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
  }
}

.ts {
  height: 60px;
  width: 50px;
  line-height: 60px;
  overflow: hidden;
  font-size: 25px;
  display: block;
  float: right;
  color: rgb(136, 134, 134);
}
.listbox2 {
  overflow: hidden;
  .unames {
    height: 60px;
    line-height: 60px;
    font-size: 28px;
    color: #c90c0c;
    margin-top: 22px;
    margin-right: 34px;
    float: right;
  }
  .iconkg {
    width: 80px;
    display: block;
    float: right;
  }  
     
}
</style>